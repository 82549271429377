import { Helmet } from 'react-helmet';
import withTranslation from '../../hoc/withTranslation';
import { useLocation } from 'react-router-dom';

const SeoTags = (props: any) => {
  const location = useLocation();

  const currentFullUrl =
    `${process.env.REACT_APP_DOMAIN_URL}${location.pathname}`.replace(
      /\/$/,
      ''
    );

  const isProductPage = /\/categories\/[^/]+\/[^/]+/.test(currentFullUrl);

  const currentPageData = isProductPage
    ? null
    : props?.translation?.pages?.find(
        (page: { url: string }) =>
          page.url.replace(/\/$/, '') === currentFullUrl
      ) ||
      props?.translation?.pages?.find(
        (page: { url: string }) =>
          page.url.replace(/\/$/, '') === process.env.REACT_APP_DOMAIN_URL
      );

  return props?.translationLoaded && currentPageData ? (
    <Helmet>
      <title>{currentPageData?.pageTitle}</title>
      <meta name='description' content={currentPageData?.description} />
      <meta name='keywords' content={props?.translation?.keywords} />
    </Helmet>
  ) : null;
};

export default withTranslation('seo')(SeoTags);
