import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props: any) => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(location);
  useEffect(() => {
    if (
      location.pathname.split('/')[1] !==
      previousLocation.pathname.split('/')[1]
    ) {
      window.history.scrollRestoration = 'manual';
      window.scroll(0, 0);
    }

    setPreviousLocation(location);
    // eslint-disable-next-line
  }, [location]);

  return <>{props.children}</>;
};
export default ScrollToTop;
