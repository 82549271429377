import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Modals from './components/Modals/Modals';
import Notifications from './components/Notifications/Notifications';
import { useBodyOverflow, useUserSession } from './utils';
import './App.scss';
import 'react-tooltip/dist/react-tooltip.css';
import HAWCCookieConsent from './components/CookieConsent/CookieConsent';
import ComparatorBar from './components/ComparatorBar/ComparatorBar';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Helmet from './components/Helmet/Helmet';
import { useTranslation } from 'react-i18next';
import { RedirectToSecurity } from './utils/redirect';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Lazy load pages and components
const IndexPage = lazy(() => import('./pages/IndexPage'));
const SectorsPage = lazy(() => import('./pages/SectorsPage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const WishlistPage = lazy(() => import('./pages/WishlistPage'));
const DraftsPage = lazy(() => import('./pages/DraftsPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const CategoriesPage = lazy(() => import('./pages/ShopPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(
  () => import('./pages/TermsAndConditionsPage')
);
const DistributorPage = lazy(() => import('./pages/DistributorPage'));
const SalesConditionsPage = lazy(() => import('./pages/SalesConditionsPage'));
const PricelistPage = lazy(() => import('./pages/PricelistPage'));
const TechAreaPage = lazy(() => import('./pages/TechAreaPage'));
const RequireAuth = lazy(() => import('./components/RequireAuth/RequireAuth'));

const LayoutRoutes = () => {
  useBodyOverflow();
  useUserSession();
  return (
    <ScrollToTop>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<IndexPage />} />
          <Route path='/register' element={<IndexPage />} />
          <Route path='/sectors' element={<RedirectToSecurity />} />
          <Route path='/sectors/:sectorName' element={<SectorsPage />} />
          <Route path='/categories' element={<CategoriesPage />} />
          <Route
            path='/categories/:categoryName'
            element={<CategoriesPage />}
          />
          <Route
            path='/categories/:categoryName/:productId'
            element={<ProductPage />}
          />
          <Route
            path='/drafts/shopping-list'
            element={
              <RequireAuth>
                <WishlistPage />
              </RequireAuth>
            }
          />
          <Route
            path='/shopping-list/drafts'
            element={
              <RequireAuth>
                <DraftsPage />
              </RequireAuth>
            }
          />
          <Route
            path='/dashboard'
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route path='/reset-password' element={<ResetPasswordPage />} />
          <Route path='/distributor-area' element={<DistributorPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
          <Route
            path='/terms-and-conditions'
            element={<TermsAndConditionsPage />}
          />
          <Route path='/sales-conditions' element={<SalesConditionsPage />} />
          <Route
            path='/tech-area'
            element={
              <RequireAuth>
                <TechAreaPage />
              </RequireAuth>
            }
          />
          <Route path='/pricelist' element={<PricelistPage />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
};

const App = () => {
  const { i18n } = useTranslation();

  let localeShortcut = i18n?.language?.slice(0, 2);

  const supportedLanguages = ['en', 'pl'];

  if (!supportedLanguages.includes(localeShortcut)) {
    localeShortcut = 'en';
    i18n.changeLanguage('en');
  }

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} />
      <Helmet language={localeShortcut} />
      <Header />
      <ComparatorBar />
      <Notifications />
      <Modals />
      <HAWCCookieConsent language={localeShortcut} />
      <div style={{ maxWidth: '1920px', margin: '0 auto' }}>
        <Routes>
          <Route path='*' element={<LayoutRoutes />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
