import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectToSecurity() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/sectors/security');
  }, [navigate]);

  return null;
}

export { RedirectToSecurity };
