import {
  CartIcon,
  Edit2Icon,
  LoginIcon,
  LogoutIcon,
  UserIcon,
} from '../Icons/UI/24/icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  Notification,
  setNotification,
} from '../../redux/slices/notificationSlice';
import s from './UserPanel.module.scss';
import SelectableNavLink from '../SelectableNavLink/SelectableNavLink';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import { useState } from 'react';
import withTranslation from '../../hoc/withTranslation';

interface IProps {
  showName?: boolean;
  translation: any;
  onAction?: any;
}

enum IconType {
  Drafts,
  Cart,
  Account,
  Logout,
  LogIn,
}

const UserPanel = ({ showName = true, translation, onAction }: IProps) => {
  const dispatch = useAppDispatch();
  const companyName = useAppSelector(
    (state) => state?.user?.userData?.user?.username
  );
  const loggedIn = useAppSelector((state) => state?.user?.loggedIn);
  const techSpecialist = useAppSelector(
    (state) => state?.user?.isTechSpecialist
  );
  const [hovered, setHovered] = useState(-1);
  const iconsData = [
    {
      name: translation?.icons?.[IconType.Drafts],
      jsx: (
        <>
          {loggedIn && !techSpecialist ? (
            <SelectableNavLink
              className={s.iconWrapper}
              activeClassName={s.activeIconWrapper}
              to='/shopping-list/drafts'
              onClick={onAction}
            >
              <Edit2Icon className={s.icon} />
            </SelectableNavLink>
          ) : (
            <div className={s.iconWrapper}>
              <Edit2Icon className={s.iconDisabled} />
            </div>
          )}
        </>
      ),
    },
    {
      name: translation?.icons?.[IconType.Cart],
      jsx: (
        <>
          {loggedIn && !techSpecialist ? (
            <SelectableNavLink
              className={s.iconWrapper}
              activeClassName={s.activeIconWrapper}
              to='/drafts/shopping-list'
              onClick={onAction}
            >
              <div style={{width:"24px",height:"24px"}}><CartIcon className={s.icon} /></div>
            </SelectableNavLink>
          ) : (
            <div className={s.iconWrapper}>
              <CartIcon className={s.iconDisabled} />
            </div>
          )}
        </>
      ),
    },
    {
      name: translation?.icons?.[IconType.Account],
      jsx: (
        <>
          {loggedIn ? (
            <SelectableNavLink
              className={s.iconWrapper}
              activeClassName={s.activeIconWrapper}
              to='/dashboard'
              onClick={onAction}
            >
              <UserIcon className={s.icon} />
            </SelectableNavLink>
          ) : (
            <div className={s.iconWrapper}>
              <UserIcon className={s.iconDisabled} />
            </div>
          )}
        </>
      ),
    },
    {
      name: loggedIn
        ? translation?.icons?.[IconType.Logout]
        : translation?.icons?.[IconType.LogIn],
      jsx: (
        <SelectableNavLink
          onClick={() => {
            if (loggedIn) {
              dispatch(setNotification(Notification.LogOut));
            } else {
              dispatch(setModal(Modal.Login));
            }
          }}
          className={s.iconWrapper}
          to={{}}
        >
          {loggedIn ? (
            <LogoutIcon className={s.icon} />
          ) : (
            <LoginIcon className={s.icon} />
          )}
        </SelectableNavLink>
      ),
    },
  ];
  return (
    <div className={s.icons}>
      {iconsData.map((iconData, index) => (
        <div
          key={index}
          onMouseEnter={() => setHovered(index)}
          onMouseLeave={() => setHovered(-1)}
          className={s.iconName}
        >
          {iconData.jsx}
          {hovered === index && <p className='body-4'>{iconData.name}</p>}
        </div>
      ))}
      {showName && <p className='body-4'>{companyName}</p>}
    </div>
  );
};
export default withTranslation('header')(UserPanel);
