import { SearchIcon } from '../Icons/UI/24/icons';
import { useEffect, useRef, useState } from 'react';
import s from './SearchBar.module.scss';
import { fetchProductsWithFields } from '../../hoc/withProducts';
import { useOnClickOutside } from 'usehooks-ts';
import { useAppDispatch } from '../../redux/hooks';
import { setBurgerOpened } from '../../redux/slices/headerSlice';
import { useAxiosAuthConfig } from '../../utils';
import { useQuery } from '@tanstack/react-query';

export enum Style {
  Default = s.defaultStyle,
  Shop = s.shopStyle,
}

export const SearchBarProducts = (props: any) => {
  const dispatch = useAppDispatch();
  const wrapperRef = useRef(null);
  const [searchBarFocused, setSearchBarFocused] = useState(false);
  const [value, setValue] = useState('');
  const [hoveredItem, setHoveredItem] = useState(-1);
  const [selected, setSelected] = useState('');
  useOnClickOutside(wrapperRef, () => setSearchBarFocused(false));
  const config = useAxiosAuthConfig();

  const valueRef = useRef(value);
  valueRef.current = value;

  useEffect(() => {
    if (valueRef.current !== '') {
      const timer = setTimeout(() => {
        refetch();
      }, 1000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [value]);

  const {
    data: products,
    status,
    refetch,
  } = useQuery({
    queryKey: ['search-products'],
    queryFn: () =>
      fetchProductsWithFields(
        ['mpn'],
        {
          mpn: { $containsi: valueRef.current },
        },
        'mpn',
        1,
        999999,
        { category: true },
        config
      ),
    enabled: false,
  });

  return (
    <div
      ref={wrapperRef}
      className={`${s.wrapper} ${props.className} ${
        props.style ? props.style.valueOf() : Style.Default.valueOf()
      } ${searchBarFocused ? 'shadow-1' : ''}`}
    >
      <div className={s.icon}>
        <SearchIcon stroke='#4196F0' />
      </div>
      <input
        className={`${s.searchBar} body-4 add-bold ${
          selected.length > 0 ? s.selected : ''
        }`}
        value={
          selected.length > 0 ? selected : props.value ? props.value : value
        }
        onChange={(event) => {
          if (selected.length > 0) {
            setSelected('');
          }
          props.setValue
            ? props.setValue(event.target.value)
            : setValue(event.target.value);
        }}
        type='text'
        autoComplete='new-password'
        placeholder={props.placeholder}
        onClick={() => setSearchBarFocused(true)}
      />
      {searchBarFocused && status === 'success' && (
        <div className={s.dropdown}>
          {products?.data?.map((product: any, index: number) => (
            <div
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(-1)}
              key={index}
              onClick={() => {
                setSearchBarFocused(false);
                setSelected(product?.mpn);
                window.location.href = `/categories/${product?.category?.name?.toLowerCase()}/${
                  product?.mpn
                }`;
                dispatch(setBurgerOpened(false));
              }}
              className={s.dropdownItem}
            >
              <p
                className={`small ${
                  hoveredItem === index
                    ? s.hover
                    : hoveredItem !== -1
                    ? s.hoverDisabled
                    : ''
                }`}
              >
                {product?.mpn}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default SearchBarProducts;
