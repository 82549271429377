import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const storedLang = localStorage.getItem('i18nextLng') ?? undefined;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
    },
    lng: storedLang,
    fallbackLng: 'en',
    supportedLngs: [
      'en-US',
      'en-GB',
      'nl-BE',
      'nl',
      'pl',
      'es',
      'de-CH',
      'de',
      'fr',
    ],
    ns: ['common'],
  });
export default i18n;
