import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ChangeLanguageNotificationState {
  language: string;
}

const initialState: ChangeLanguageNotificationState = {
  language: '',
};

export const changeLanguageNotificationSlice = createSlice({
  name: 'changeLanguageNotification',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = changeLanguageNotificationSlice.actions;

export default changeLanguageNotificationSlice.reducer;
