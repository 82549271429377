import { setBurgerOpened } from '../../redux/slices/headerSlice';
import SlantedMobile from './slanted-mobile.svg';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import sm from './HeaderMobile.module.scss';
import s from './Header.module.scss';
import UserPanel from '../UserPanel/UserPanel';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import Button, { Size, Style } from '../Button/Button';
import { BurgerIcon, CloseIcon } from '../Icons/UI/24/icons';
import { SearchBarProducts } from '../SearchBar/SearchBar';
import { Fragment, useEffect, useState } from 'react';
import SectorsMobile from './sectors-mobile.webp';
import CategoriesMobile from './categories-mobile.webp';
import DistributorAreaMobile from './distributor-area-mobile.webp';
import OurMissionMobile from './our-mission-mobile.webp';
import SupportMobile from './support-mobile.webp';
import { DownSquareIcon } from '../Icons/Arrows/24/icons';
import { AnimatePresence } from 'framer-motion';
import TemplateAnimation, {
  AnimationTemplates,
} from '../Animations/TemplateAnimation';
import { RightIcon as RightArrowIcon } from '../Icons/Arrows/24/icons';
import { setLanguage } from '../../redux/slices/notifications/changeLanguageNotificationSlice';
import i18n from '../../i18n';
import {
  Notification,
  setNotification,
} from '../../redux/slices/notificationSlice';
import { ButtonType } from './Header';

const HeaderMobile = (props: any) => {
  const dispatch = useAppDispatch();
  const [mobileListItems, setMobileListItems] = useState<any>([]);
  const toggleMobileListItem = (index: number) => {
    let newMobileListItems = [...mobileListItems];
    newMobileListItems[index].opened = !newMobileListItems[index].opened;
    setMobileListItems(newMobileListItems);
  };
  const navigate = useNavigate();
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  useEffect(() => {
    setMobileListItems([
      {
        name: props?.headerTranslation?.pages?.[0]?.title,
        background: SectorsMobile,
        opened: false,
        links: props?.sectors
          ?.sort((a: any, b: any) => a.orderOfAppearance - b.orderOfAppearance)
          ?.map((sector: any) => ({
            name: sector?.name,
            to: `/sectors/${sector?.hash}`,
          })),
      },
      {
        name: props?.headerTranslation?.categories?.title,
        background: CategoriesMobile,
        opened: false,
        links: [...props?.resource]
          ?.sort((a: any, b: any) => a.orderOfAppearance - b.orderOfAppearance)
          ?.reduce((result, cat: any) => {
            result.push({
              name: cat.name.toUpperCase(),
              to: `/categories${cat.link}`,
            });

            return result;
          }, []),
      },
      {
        name: props?.headerTranslation?.pages?.[1]?.title,
        background: DistributorAreaMobile,
        link: props?.headerTranslation?.pages?.[1]?.link,
      },
      {
        name: props?.headerTranslation?.pages?.[2]?.title,
        background: OurMissionMobile,
        scrollTo: props?.headerTranslation?.pages?.[2],
      },
      {
        name: props?.headerTranslation?.pages?.[3]?.title,
        background: SupportMobile,
        scrollTo: props?.headerTranslation?.pages?.[3],
      },
    ]);
  }, [
    props?.headerTranslation?.pages,
    props?.headerTranslation?.categories?.title,
    props?.resource,
    props?.sectors,
  ]);
  const mobileLook = () => {
    if (props.burgerOpened) {
      return (
        <>
          <UserPanel
            showName={false}
            onAction={() => dispatch(setBurgerOpened(false))}
          />
          <CloseIcon
            stroke='#0663C7'
            onClick={() => dispatch(setBurgerOpened(false))}
          />
        </>
      );
    } else {
      if (props.loggedIn) {
        return (
          <>
            <UserPanel
              showName={false}
              onAction={() => dispatch(setBurgerOpened(false))}
            />
            <BurgerIcon
              stroke='#0663C7'
              onClick={() => dispatch(setBurgerOpened(true))}
            />
          </>
        );
      } else {
        return (
          <>
            <Button
              onClick={() => dispatch(setModal(Modal.Login))}
              style={Style.BlackOutline}
              size={Size.Small}
            >
              <b>{props?.headerTranslation?.buttons?.[ButtonType.SignIn]}</b>
            </Button>
            <BurgerIcon
              stroke='#0663C7'
              onClick={() => dispatch(setBurgerOpened(true))}
            />
          </>
        );
      }
    }
  };
  return (
    <>
      <div className={sm.header}>
        <RouterLink
          to='/'
          onClick={() => dispatch(setBurgerOpened(false))}
          className={s.logo}
        >
          <img src={SlantedMobile} alt='logo-background' />
          <div className={s.logoImage}>
            <img
              className={sm.logo}
              src='/img/logo/logo-header.png'
              alt='logo-header'
            />
          </div>
        </RouterLink>
        <div className={sm.headerButtons}>{mobileLook()}</div>
      </div>
      {props.burgerOpened && (
        <div className={sm.headerList}>
          <div className={sm.searchBarWrapper}>
            <SearchBarProducts
              className={sm.searchBar}
              searchProducts={true}
              placeholder={props?.headerTranslation?.searchbar?.text}
            />
          </div>
          <div className={sm.mainList}>
            {mobileListItems?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => {
                      if (item.links?.length > 0) {
                        toggleMobileListItem(index);
                      } else if (item.link) {
                        navigate(item.link);
                        dispatch(setBurgerOpened(false));
                      } else {
                        props.scrollTo(item.scrollTo);
                        dispatch(setBurgerOpened(false));
                      }
                    }}
                    className={sm.mainListItem}
                  >
                    {item.background && (
                      <div className={sm.backgroundImage}>
                        <img
                          src={item.background}
                          alt={item.name.toLowerCase()}
                        />
                      </div>
                    )}
                    <div
                      className={`${sm.mainListItemContent} ${
                        item.background ? sm.absolute : ''
                      }`}
                    >
                      <p className='small'>
                        <b>{item.name}</b>
                      </p>
                      {item.links?.length > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            transform: item.opened ? 'rotate(180deg)' : '',
                          }}
                        >
                          <DownSquareIcon stroke='#F5F7FA' />
                        </div>
                      )}
                    </div>
                  </div>
                  <AnimatePresence>
                    {item.opened && (
                      <TemplateAnimation
                        templates={[
                          AnimationTemplates.Height,
                          AnimationTemplates.Opacity,
                        ]}
                        duration={0.5}
                        once={true}
                        exit={true}
                        className={sm.listDropdown}
                      >
                        {item.links?.map((link: any, i: number) => (
                          <RouterLink
                            key={i}
                            onClick={() => dispatch(setBurgerOpened(false))}
                            to={link.to}
                            className={sm.listDropdownItem}
                          >
                            <p className='small'>{link.name}</p>
                            <RightArrowIcon stroke='#4196F0' />
                          </RouterLink>
                        ))}
                      </TemplateAnimation>
                    )}
                  </AnimatePresence>
                </Fragment>
              );
            })}
          </div>
          <div className={sm.secondaryList}>
            <>
              <div
                className={sm.secondaryListItem}
                onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
              >
                <p className='small'>
                  <b>
                    {props?.headerTranslation?.buttons?.[ButtonType.Language]}
                  </b>
                </p>
                <div
                  style={{
                    display: 'flex',
                    transform: languageDropdownOpen ? 'rotate(180deg)' : '',
                  }}
                >
                  <DownSquareIcon stroke='#212121' />
                </div>
              </div>
              <AnimatePresence>
                {languageDropdownOpen && (
                  <TemplateAnimation
                    templates={[
                      AnimationTemplates.Height,
                      AnimationTemplates.Opacity,
                    ]}
                    duration={0.5}
                    once={true}
                    exit={true}
                    className={sm.listDropdown}
                  >
                    {props?.languageTranslation?.languages?.map(
                      (language: any) => (
                        <div
                          onClick={() => {
                            if (i18n.language.slice(0, 2) !== language.short) {
                              dispatch(setLanguage(language.short));
                              dispatch(
                                setNotification(Notification.ChangeLanguage)
                              );
                            }
                          }}
                          key={language.short}
                          className={sm.listDropdownItem}
                        >
                          <p className='small'>{language.translation}</p>
                          <p
                            className='small'
                            style={{
                              color:
                                i18n.language.slice(0, 2) === language.short
                                  ? '#4196F0'
                                  : '',
                            }}
                          >
                            {i18n.language.slice(0, 2) === language.short ? (
                              <b>
                                {props?.languageTranslation?.buttons?.selected}
                              </b>
                            ) : (
                              props?.languageTranslation?.buttons?.select
                            )}
                          </p>
                        </div>
                      )
                    )}
                  </TemplateAnimation>
                )}
              </AnimatePresence>
            </>
          </div>
          {!props?.loggedIn && (
            <div className={sm.loginButtons}>
              <p
                onClick={() => dispatch(setModal(Modal.Login))}
                className={`small ${sm.button}`}
              >
                <b>{props?.headerTranslation?.buttons?.[ButtonType.SignIn]}</b>
              </p>
              <Button
                onClick={() => dispatch(setModal(Modal.Register))}
                customFontSize={true}
                style={Style.Primary}
                className={sm.button}
                size={Size.Small}
              >
                <p className='small'>
                  <b>
                    {props?.headerTranslation?.buttons?.[ButtonType.GetStarted]}
                  </b>
                </p>
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HeaderMobile;
