import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import Button, { IconPlacement, Size, Style } from '../Button/Button';
import {
  DownIcon,
  RightIcon,
  RightIcon as RightIcon24,
} from '../Icons/Arrows/24/icons';
import { SearchBarProducts } from '../SearchBar/SearchBar';
import SelectableNavLink from '../SelectableNavLink/SelectableNavLink';
import UserPanel from '../UserPanel/UserPanel';
import { ButtonType } from './Header';
import s from './Header.module.scss';

export const HeaderDesktopUpper = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className={s.upperWrapper}>
      <div className={s.left}>
        {props?.headerTranslation?.pages?.map((elem: any) => {
          if (elem.link === 'support-center' || elem.link === 'our-mission') {
            return (
              <span
                key={elem.title}
                className='medium-bold add-pointer'
                onClick={() => props.scrollTo(elem)}
              >
                {elem.title}
              </span>
            );
          } else {
            return (
              <span
                key={elem.title}
                className='medium-bold add-pointer'
                onClick={() => navigate(elem.link)}
              >
                {elem.title}
              </span>
            );
          }
        })}
      </div>
      <div className={s.right}>
        <div className={s.rightButtons}>
          <Button
            className={s.consultingButton}
            style={Style.Orange}
            minimalContentHeight='24px'
            iconHover={<RightIcon24 stroke='#FEFEFE' />}
            iconPlacement={IconPlacement.Right}
            size={Size.Normal}
            onClick={() => dispatch(setModal(Modal.Consulting))}
          >
            <b>
              {
                props?.headerTranslation?.buttons?.[
                  ButtonType.ProductConsulting
                ]
              }
            </b>
          </Button>
          {props.loggedIn ? (
            <UserPanel />
          ) : (
            <Button
              style={Style.BlackOutline}
              size={Size.Normal}
              icon={<RightIcon stroke='#212121' />}
              iconHover={<DownIcon stroke='#FEFEFE' />}
              iconPlacement={IconPlacement.Right}
              onClick={() => dispatch(setModal(Modal.Login))}
            >
              {props?.headerTranslation?.buttons?.[ButtonType.SignIn]}
            </Button>
          )}
        </div>
        <p
          onClick={() => dispatch(setModal(Modal.Language))}
          className='medium-bold'
        >
          {props?.headerTranslation?.buttons?.[ButtonType.Language]}
        </p>
      </div>
    </div>
  );
};

export const HeaderDesktopLower = (props: any) => {
  return (
    <div className={s.lower}>
      <div className={s.lowerWrapper}>
        <div className={s.left}>
          <p className={`medium ${s.noLink}`}>
            {props?.headerTranslation?.categories?.title}:
          </p>

          {[...props?.resource]
            ?.sort(
              (a: any, b: any) => a.orderOfAppearance - b.orderOfAppearance
            )
            ?.map((cat: any) => {
              const linkStyle =
                cat.link === '/product-archives' ? { color: 'orange' } : {};

              return (
                <SelectableNavLink
                  key={cat.name}
                  activeClassName={s.selected}
                  className='medium-bold'
                  to={`/categories${cat.link}`}
                  style={linkStyle} // Apply the conditional style here
                >
                  {cat.name.toUpperCase()}
                </SelectableNavLink>
              );
            })}
        </div>
        <div className={s.right}>
          <SearchBarProducts
            searchProducts={true}
            className={s.searchBar}
            placeholder={props?.headerTranslation?.searchbar?.text}
          />
        </div>
      </div>
    </div>
  );
};
