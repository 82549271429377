import Slanted from './slanted.svg';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { useMediaQuery } from 'usehooks-ts';
import SloganBar from '../SloganBar/SloganBar';
import s from './Header.module.scss';
import withTranslation from '../../hoc/withTranslation';
import { scroller } from 'react-scroll';
import { Events } from 'react-scroll';
import { useCallback, useEffect, useRef, useState } from 'react';
import HeaderMobile from './HeaderMobile';
import { HeaderDesktopUpper, HeaderDesktopLower } from './HeaderDesktop';
import { useResource } from '../../hoc/withResource';

export enum ButtonType {
  ProductConsulting,
  SignIn,
  Language,
  GetStarted,
}

const Header = (props: any) => {
  const headerTranslation = props?.translation?.[0]?.data;
  const languageTranslation = props?.translation?.[1]?.data;
  const burgerOpened = useAppSelector((state) => state.header.burgerOpened);
  const max1600 = useMediaQuery('(max-width: 1600px)');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const loggedIn = useAppSelector((state) => state?.user?.loggedIn);
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [scrollPosY, setScrollPosY] = useState(0);
  const { data: categories, status: categoriesStatus } =
    useResource('categories');
  const { data: sectors, status: sectorsStatus } = useResource('sectors');

  useEffect(() => {
    Events.scrollEvent.register('begin', function (to, element) {});
    Events.scrollEvent.register('end', function (to, element) {});
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);
  const onScroll = useCallback(
    (e: any) => {
      let header = headerRef?.current;
      if (header) {
        if (scrollPosY > window.scrollY) {
          header.style.top = '0';
        } else {
          header.style.top = '-' + header.clientHeight + 'px';
        }
      }
      setScrollPosY(window.scrollY);
    },
    [scrollPosY]
  );
  useEffect(() => {
    window.addEventListener('scroll', onScroll, {
      passive: false,
    });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
  const scrollTo = (elem: any) => {
    if (elem.link === 'our-mission') {
      if (location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          scroller.scrollTo(elem.link, {
            ignoreCancelEvents: true,
            duration: 700,
            delay: 200,
            smooth: 'easeInOutQuart',
          });
        }, 1200);
      } else {
        setTimeout(() => {
          scroller.scrollTo(elem.link, {
            ignoreCancelEvents: true,
            duration: 700,
            delay: 0,
            smooth: 'easeInOutQuart',
          });
        }, 0);
      }
    } else {
      setTimeout(() => {
        scroller.scrollTo(elem.link, {
          ignoreCancelEvents: true,
          duration: 700,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }, 0);
    }
  };
  return (
    categoriesStatus === 'success' &&
    sectorsStatus === 'success' && (
      <>
        <header
          ref={headerRef}
          style={{
            position: burgerOpened ? 'fixed' : 'sticky',
            top: burgerOpened ? '0' : headerRef?.current?.style?.top,
            height: burgerOpened ? '100%' : 'auto',
            overflow: 'visible',
          }}
          className={s.header}
        >
          <div className={s.upper}>
            {isMobile ? (
              <HeaderMobile
                burgerOpened={burgerOpened}
                loggedIn={loggedIn}
                headerTranslation={headerTranslation}
                languageTranslation={languageTranslation}
                sectors={sectors}
                resource={categories}
                scrollTo={scrollTo}
              />
            ) : (
              <>
                <RouterLink to='/' className={s.logo}>
                  {!max1600 && <img src={Slanted} alt='logo-background' />}
                  <div className={s.logoImage}>
                    <img src='/img/logo/logo-header.png' alt='logo-header' />
                  </div>
                </RouterLink>
                <HeaderDesktopUpper
                  headerTranslation={headerTranslation}
                  loggedIn={loggedIn}
                  resource={categories}
                  scrollTo={scrollTo}
                />
              </>
            )}
          </div>
          {!isMobile && (
            <HeaderDesktopLower
              headerTranslation={headerTranslation}
              resource={categories}
            />
          )}
        </header>
        {location.pathname === '/dashboard' ||
        location.pathname === '/distributor-area' ||
        location.pathname === '/sales-conditions' ||
        location.pathname === '/terms-and-conditions' ||
        location.pathname === '/privacy-policy' ||
        location.pathname === '/tech-area' ? (
          <></>
        ) : (
          <SloganBar slogan={headerTranslation?.slogan?.text} />
        )}
      </>
    )
  );
};
export default withTranslation(['header', 'modal-language'])(Header);
