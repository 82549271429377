import React, { useCallback, useEffect, lazy, Suspense } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Modal, setModal } from '../../redux/slices/modalSlice';
import {
  LoginError,
  setError as setLoginError,
} from '../../redux/slices/modals/loginModalSlice';
import {
  setError as setRegisterError,
  RegisterError,
} from '../../redux/slices/modals/registerModalSlice';
import {
  ConsultingType,
  setType,
} from '../../redux/slices/modals/consultingModalSlice';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

// Lazy load the modal components
const LoginModal = lazy(() => import('./Modal/LoginModal/LoginModal'));
const RegisterModal = lazy(() => import('./Modal/RegisterModal/RegisterModal'));
const ConsultingModal = lazy(
  () => import('./Modal/ConsultingModal/ConsultingModal')
);
const LanguageModal = lazy(() => import('./Modal/LanguageModal/LanguageModal'));
const ChangePasswordModal = lazy(
  () => import('./Modal/ChangePasswordModal/ChangePasswordModal')
);
const ForgotPasswordModal = lazy(
  () => import('./Modal/ForgotPasswordModal/ForgotPasswordModal')
);
const ResetPasswordModal = lazy(
  () => import('./Modal/ResetPasswordModal/ResetPasswordModal')
);
const ComparatorModal = lazy(
  () => import('./Modal/ComparatorModal/ComparatorModal')
);

const Modals = () => {
  const currentLocation = useLocation();
  const dispatch = useAppDispatch();
  const modal: Modal = useAppSelector((state) => state.modal.modal);
  const animate: boolean = useAppSelector((state) => state.modal.animateModal);

  useEffect(() => {
    if (currentLocation.pathname === '/register') {
      dispatch(setModal(Modal.Register));
    }
  }, [currentLocation.pathname, dispatch]);

  const getModal = useCallback(
    (modal: Modal): React.ReactNode => {
      switch (modal) {
        case Modal.Login:
          return <LoginModal animate={animate} />;
        case Modal.Register:
          return <RegisterModal animate={animate} />;
        case Modal.Consulting:
          return <ConsultingModal animate={animate} />;
        case Modal.Language:
          return <LanguageModal animate={animate} />;
        case Modal.ChangePassword:
          return <ChangePasswordModal animate={animate} />;
        case Modal.ForgotPassword:
          return <ForgotPasswordModal animate={animate} />;
        case Modal.ResetPassword:
          return <ResetPasswordModal animate={animate} />;
        case Modal.Comparator:
          return <ComparatorModal animate={animate} />;
        default:
          return null;
      }
    },
    [animate]
  );

  useEffect(() => {
    if (modal === Modal.None) {
      dispatch(setType(ConsultingType.None));
      dispatch(setLoginError(LoginError.None));
      dispatch(setRegisterError(RegisterError.None));
    }
  }, [modal, dispatch]);

  return (
    <AnimatePresence>
      {modal !== Modal.None && (
        <Suspense fallback={<div>Loading...</div>}>{getModal(modal)}</Suspense>
      )}
    </AnimatePresence>
  );
};

export default Modals;
